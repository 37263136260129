'use client';
import { useEffect, useRef } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { closeModalAtom, modalDataAtom } from '../../Atoms/Modal/Modal.atom';

import styles from './Modal.module.css';
import { ModalCloseIcon } from '../../Entities/Modal/Modal.entity';
import { Typography } from '@gds/Typography/Typography';
import Button from '@gds/Button/Button';

export const ModalHeader = ({ customCloseIcon }: ModalCloseIcon) => {
  const { header, onClose, headerClassName } = useAtomValue(modalDataAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dialogRef?.current?.focus();
  }, [dialogRef.current]);

  const close = () => {
    closeModal();
    onClose?.();
  };

  return (
    <div className={`${headerClassName} ${styles.header}`} ref={dialogRef}>
      <Typography variant="h5" className={styles.title}>
        {header}
      </Typography>

      {onClose && (
        <Button
          className={styles.closeButton}
          data-test-id="close-button"
          variant="link"
          leftIcon={customCloseIcon}
          onClick={close}
          size="large"
        />
      )}
    </div>
  );
};
