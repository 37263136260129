'use client';

import { FC, useState } from 'react';

import { ChevronDown } from '@gds/Icons/Paths/ChevronDown';
import { Typography } from '@gds/Typography/Typography';

import { TrackedLink } from 'Components/TrackedLink/TrackedLink';

import { FooterGroup } from '../Footer.entity';

import styles from './FooterLinks.module.css';

export const FooterLinks: FC<{ columns: FooterGroup[] | undefined }> = ({ columns }) => {
  const [expandedGroup, setExpandedGroup] = useState<Record<string, boolean>>({
    'group0-0': true,
  });

  const toggleMenu = (group: string) => {
    setExpandedGroup(expandedGroup => ({
      ...expandedGroup,
      [group]: !expandedGroup[group],
    }));
  };

  return (
    <>
      {columns?.map(({ footerLinksGroup }, index) => {
        const columnIndex = index;
        return (
          <div className={styles.groups} key={index + 1}>
            {footerLinksGroup?.map(({ heading, footerLinkItems }, groupIndex) => {
              return (
                <div className={styles.group} key={groupIndex + 1}>
                  <button
                    data-expanded={expandedGroup[`group${columnIndex}-${groupIndex}`] ?? false}
                    onClick={() => toggleMenu(`group${columnIndex}-${groupIndex}`)}
                    role="button"
                  >
                    <Typography Component="span" variant="body2">
                      {heading}
                    </Typography>
                    <ChevronDown fontSize={16} />
                  </button>
                  <ul
                    data-expanded={expandedGroup[`group${columnIndex}-${groupIndex}`] ?? false}
                    className={styles.menuList}
                    data-test-id="footer-group-links"
                  >
                    {footerLinkItems?.map(({ label, href, footerLinkTarget }, index) => {
                      return (
                        <li className={styles.menuListItem} key={index + 1}>
                          {href ? (
                            <TrackedLink
                              prefetch={false}
                              href={href}
                              {...(footerLinkTarget?.length && { target: '_blank' })}
                            >
                              <Typography Component="span" variant="body4">
                                {label}
                              </Typography>
                            </TrackedLink>
                          ) : (
                            <Typography Component="span" variant="body4">
                              {label}
                            </Typography>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};
